import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { config } from 'config';
import * as yup from 'yup';
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import BasicModal from 'components/ui/Modal';
import { convertToRawAndStringify } from '@eggmed/common/utils/DraftUtils';
import { stringToDate, addStart, sanitizeData } from 'utils';
import { editModeDefaultValues } from './utils';
import EventForm from './EventForm';
import {
  IAppointmentSubmitedData as IAppointmentSubmittedData,
  IDateRange,
} from './types';
import { useProvider } from '../state/SchedulePageProvider';

import { convertDateLikeMeet, convertTime } from 'utils/dateUtils';
import { useToast } from 'hooks/useToast';

import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { DOCTOR_FREE_TIMES } from 'pages/PatientAppointmentPage/AddEventPage';
import Dayjs from 'dayjs';
import EventTab from './EventTab';
import RecurringModal from './RecurringModal';
import { ReactComponent as RecurringModalIcon } from 'assets/RecurringModalIcon.svg';
import useGenerateAccount from '@eggmed/graphql-client/operations/patientOperations/useGenerateAccount';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { EventType } from './Conflicts/ConflictExpanded';
import { useTag } from 'pages/AdminPage/ManageTags/useTag';
import TagModal from 'pages/AdminPage/ManageTags/TagModal';
import { schema as schemaTag } from 'pages/AdminPage/ManageTags';
import AddPatientModal from 'components/Layout/header/AddPatientModal';
import { schema as schemaAddPatient } from 'components/Layout/header';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { useSnackbar } from 'hooks/useSnackbar';
import {
  CardNumberElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useProvider as authContext } from 'contexts/AuthContext';
import { useRate } from 'pages/AdminPage/Payment/useRate';

enum ISessionLocation {
  InPerson = 'In-person',
  Online = 'Online',
}
const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess ? '#EFF6FF' : '#F8FAFB',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    outline: '0px',
    padding: theme.spacing(4),
    position: 'relative',
    '& ::-webkit-scrollbar': {
      webkitAppearance: 'none',
    },
    maxHeight: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '10px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100vw',
      maxHeight: '100vh',
      padding: theme.spacing(1),
      borderRadius: '0px',
    },
  },
  tab: {
    display: ({ editMode }: { editMode: boolean }) => editMode && 'none',
  },
  back: {
    cursor: 'pointer',
    marginRight: '5px',
  },
  eventForm: {
    maxHeight: '80vh',
    overflow: 'scroll',
    [theme.breakpoints.only('xl')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '90vw',
      maxHeight: '98.5vh',
    },
  },
  modalContent: {
    backgroundColor: 'white',
    width: '74vw',
    maxWidth: '74vw',
    height: '87vh',
    maxHeight: '87vh',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('lg')]: {
      width: '75vw',
      maxWidth: '75vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '77vw',
      maxWidth: '77vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '91vw',
      maxWidth: '91vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      borderRadius: '0px',
    },
  },
  succesfullIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '30px',
  },
  textAppointment: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(27),
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: '#1F61DC',
  },
  titleText: {
    fontSize: '30px',
    fontWeight: 600,
    color: '#1F61DC',
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  appointmentModal: {
    width: '50vw',
    [theme.breakpoints.up('lg')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '37vw',
    },
  },
}));

const DOCTOR_VIDEO_ROOM_QUERY = gql`
  query GetDoctorVideoRoomLink($doctorId: ID!, $doctor: ID) {
    videoRoom(doctorId: $doctorId) {
      _id
      room_id
    }
    doctorStripeAccount(doctor: $doctor) {
      last4
      bank_name
      routing_number
      payouts_enabled
      charges_enabled
    }
  }
`;

interface IAddEventPageProps {
  addEvent?: any;
  doctorId: string;
  closeModal: () => void;
  editMode: boolean;
  eventData?: any;
  loadingAddAppointment?: boolean;
  loadingEditAppointment?: boolean;
  loadingEditEvent?: boolean;
  modalAddOpen?: boolean;
  clickableEvent?: { startDate?: string };
  isClickable?: boolean;
  isBack?: boolean;
  setIsClickable?: (isClickable?: boolean) => void;
  handleSubmitEvent?: any;
  handleOpen?: () => void;
}
const schema = yup.object().shape({
  patient: yup
    .object()
    .typeError('Client is a required field')
    .required('Client is a required field'),
  members: yup.array(),
  sessionType: yup
    .object()
    .typeError('Service is a required field')
    .required('Service is a required field'),
});
enum Typename {
  Appointment = 'Appointment',
  Event = 'Event',
}
export default function AddEventPage({
  addEvent,
  editMode = false,
  doctorId,
  eventData,
  closeModal,
  loadingAddAppointment,
  loadingEditAppointment,
  loadingEditEvent,
  modalAddOpen,
  clickableEvent,
  isClickable,
  setIsClickable,
  handleSubmitEvent,
  isBack = false,
  handleOpen,
}: IAddEventPageProps): JSX.Element {
  const { roles } = useRate('', '', false);

  const { t } = useTranslation();
  // add tag
  const titleTag = editMode
    ? t('Edit session > Add new tag')
    : t('Create session > Add new tag');
  const {
    addTag,
    createLoading,
    open: openTag,
    handleClick,
    handleClose: closeTag,
    selectedColor,
    setSelectedColor,
  } = useTag(false);
  // const { data: dataTemplates } = useGetAutoSendTemplates();

  const methodsAddTag = useForm({ resolver: yupResolver(schemaTag) });

  async function onAddTag(data: { tag: string }) {
    const { tag } = data || {};

    const result = await addTag({
      variables: {
        tagInput: { tag, color: selectedColor ? selectedColor : '#BDBDBD' },
      },
    });
    setSelectedColorId(result?.data?.addTag?._id);
    triggerSnack();
    closeTag();
  }

  // add patient
  const [checked, setChecked] = React.useState(true);
  const [openAddPatient, setOpenAddPatient] = useState<boolean>(false);
  const { doctor, dataSubscription } = useAuth();

  const methodsAddPatient = useForm({
    resolver: yupResolver(schemaAddPatient),
    defaultValues: {
      text_about_upcoming: true,
      text_about_canceled: true,
      email_about_canceled: true,
      email_about_upcoming: true,
      selectedTemplates: dataSubscription?.doctorAutoSendTemplates.map(
        (el) => el._id
      ),
      sendIntake: true,
      assignedRoles: roles?.ownerRoles?.map((el) => {
        return { ...el, name: el.name, id: el?._id };
      }),
      assignedProviders: [
        {
          ...doctor,
          name: `${doctor?.firstname} ${doctor?.lastname}`,
          id: doctor?._id,
          doctorData: {
            _id: doctor?._id,
          },
        },
      ],
    },
  });
  React.useEffect(() => {
    if (dataSubscription) {
      methodsAddPatient.reset({
        text_about_upcoming: true,
        text_about_canceled: true,
        email_about_canceled: true,
        email_about_upcoming: true,
        selectedTemplates: dataSubscription?.doctorAutoSendTemplates.map(
          (el) => el._id
        ),
        sendIntake: true,
        assignedRoles: roles?.ownerRoles?.map((el) => {
          return { ...el, name: el.name, id: el?._id };
        }),
        assignedProviders: [
          {
            ...doctor,
            name: `${doctor?.firstname} ${doctor?.lastname}`,
            id: doctor?._id,
            doctorData: {
              _id: doctor?._id,
            },
          },
        ],
      });
    }
  }, [dataSubscription, methodsAddPatient.reset, roles?.ownerRoles, doctor]);

  const { register, control, errors, watch, setValue, setError } =
    methodsAddPatient;
  const commonProps = { register, control, errors, watch, setValue, setError };
  function handleChange(value) {
    setChecked(value);
  }

  const onAddPatientClose = () => {
    setOpenAddPatient(false);
  };

  const stripe = useStripe();
  const elements = useElements();
  const { user } = authContext();
  async function onAddPatient(field) {
    let paymentMethodToken = undefined;
    if (field.payment) {
      const cardElement = elements.getElement(CardNumberElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: `${user?.firstname} ${user?.lastname}`,
        },
      });
      paymentMethodToken = paymentMethod.id;
    }
    const { selectedTemplates, sendIntake, payment, country, ...rest } = field;
    const username = `${rest.firstname} ${rest.lastname}`;
    try {
      const result = await generateAccount({
        variables: {
          userInput: {
            ...rest,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            username,
            isAdult: checked,
            paymentMethodToken,
            selectedTemplates: sendIntake ? selectedTemplates : undefined,
            assignedRoles: field?.assignedRoles?.map((el) => el?._id),
            assignedProviders: field?.assignedProviders?.map(
              (el) => el?.doctorData?._id
            ),
          },
        },
      });
      const addedPatient = result?.data?.generateAccount?.patient;

      methods.reset({
        ...methods.getValues(),
        patient: {
          ...addedPatient,
          name: `${addedPatient?.firstname ?? ''} ${
            addedPatient?.lastname ?? ''
          }`,
        },
      });
      triggerSnack();
      onAddPatientClose();
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: error?.message,
      });
    }
  }

  const {
    frequencyExpand,
    eventRange,
    start,
    end,
    loadingAddEvent,
    customValue,
    setCustomValue,
    doctorData,
    currentEvent,
    setFrequencyExpand,
    handleBack,
    handleOpenDelete,
    handelDelete,
    openDeleteModal,
    handleCloseDeleteModal,
    handleChangeCurrentDate,
    selectedColorId,
    setSelectedColorId,
    isFromConflict,
    feedMode,
  } = useProvider();

  const {
    data: doctorVideoRoomData,
    loading: loadingStripePayout,
    error: doctorVideoRoomError,
  } = useQuery(DOCTOR_VIDEO_ROOM_QUERY, {
    variables: { doctorId, doctor: doctorId },
  });

  const isSession = customValue === 0;
  const title = !editMode
    ? isSession
      ? t('Create session')
      : t('Create event')
    : isSession
    ? t('Edit session')
    : t('Edit event');
  const {
    generateAccount,
    loading: generateAccountLoading,
    error: errorGenerateAccount,
  } = useGenerateAccount();
  const [open, setOpen] = React.useState(false);
  const [infos, setInfos] = React.useState({});
  const timeValues = addStart(isClickable ? clickableEvent : eventData);
  const [time, setTime] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [pickedRightTime, setPickedRightTime] = useState<boolean>(false);
  const { triggerSnack } = useSnackbar();
  useEffect(() => {
    setTime(timeValues);
  }, [eventData, isClickable]);
  const [errorMsg, setErrorMsg] = React.useState('');
  const { openSuccess, triggerToast } = useToast();
  const classes = useStyles({ openSuccess, editMode });

  const [addPatientError, setAddPatientError] = useState('');
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: editMode
      ? new Date(stringToDate(eventData.startDate))
      : !isClickable
      ? convertDateLikeMeet(Date.now())
      : new Date(stringToDate(clickableEvent?.startDate)),
    endDate: editMode
      ? new Date(stringToDate(eventData.endDate))
      : new Date(
          new Date(convertDateLikeMeet(Date.now())).setHours(
            convertDateLikeMeet(Date.now()).getHours() + 1
          )
        ),
  });
  const getDateWithOutTime = new Date(dateRange?.startDate).toDateString();
  const getDateWithOutTimeEventStart = new Date(
    eventRange?.startDate
  ).toDateString();
  const getDateWithOutTimeEventEnd = new Date(
    eventRange?.endDate
  ).toDateString();
  const startDateTime = new Date(
    new Date(new Date(dateRange?.startDate).setMinutes(0)).setHours(10)
  )
    .toISOString()
    .replace('.935Z', '.000+00:00');
  const endDateTime = stringToDate(
    new Date(startDateTime).setHours(20).toString()
  )
    .toISOString()
    .replace('.837Z', '.000+00:00');
  const dateNow = Dayjs(new Date()).format('DD/MM/YY');
  const dateBgin = Dayjs(new Date(dateRange.startDate)).format('DD/MM/YY');
  const alwaysDate = new Date(
    new Date(new Date(dateRange?.startDate).setHours(8)).setMinutes(0)
  )
    .toISOString()
    .replace('.000Z', '.000+00:00');

  const { payouts_enabled, charges_enabled } =
    doctorVideoRoomData?.doctorStripeAccount || {};
  const isActivePayout =
    payouts_enabled && charges_enabled && !loadingStripePayout;

  const methods = useForm({
    resolver: isSession && yupResolver(schema),
    defaultValues: editMode
      ? editModeDefaultValues(eventData)
      : {
          isPrepay: dataSubscription?.defaultRate?.isPrepayment || false,
          activeMeeting:
            dataSubscription?.defaultRate?.location ===
            ISessionLocation.InPerson
              ? false
              : true,
          locationPlace:
            dataSubscription?.defaultRate?.locationPlace || 'Eggmed',
          externalUrl: dataSubscription?.defaultRate?.externalUrl || undefined,
          sessionType: dataSubscription?.defaultRate,
          locationInPerson: {
            name: dataSubscription?.defaultRate?.locationId?.name || '',
            _id: dataSubscription?.defaultRate?.locationId?._id || null,
          },
        },
  });
  const { reset } = methods;
  React.useEffect(() => {
    if (methods?.watch('sessionType')) {
      methods?.setValue(
        'isPrepay',
        methods?.watch('sessionType')?.isPrepayment
      );
      methods?.setValue(
        'activeMeeting',
        methods?.watch('sessionType')?.location === ISessionLocation.InPerson
          ? false
          : true
      );
      methods?.setValue(
        'locationPlace',
        methods?.watch('sessionType')?.locationPlace
      );
      methods?.setValue(
        'externalUrl',
        methods?.watch('sessionType')?.externalUrl
      );
      methods?.setValue('locationInPerson', {
        name: methods?.watch('sessionType')?.locationInPerson?.name,
        _id: methods?.watch('sessionType')?.locationInPerson?._id,
      });
    }
  }, [methods?.watch('sessionType')]);
  const handleDefaultValues = useCallback(
    (eventData) => {
      reset(
        editMode
          ? editModeDefaultValues(eventData)
          : {
              isPrepay: dataSubscription?.defaultRate?.isPrepayment || false,
              activeMeeting:
                dataSubscription?.defaultRate?.location ===
                ISessionLocation.InPerson
                  ? false
                  : true,
              locationPlace:
                dataSubscription?.defaultRate?.locationPlace || 'Eggmed',
              externalUrl:
                dataSubscription?.defaultRate?.externalUrl || undefined,
              sessionType: dataSubscription?.defaultRate,
              locationInPerson: {
                name: dataSubscription?.defaultRate?.locationId?.name || '',
                _id: dataSubscription?.defaultRate?.locationId?._id || null,
              },
            }
      );
      setDateRange({
        startDate: editMode
          ? new Date(stringToDate(eventData.startDate))
          : !isClickable
          ? convertDateLikeMeet(Date.now())
          : new Date(stringToDate(clickableEvent?.startDate)),
        endDate: editMode
          ? new Date(stringToDate(eventData.endDate))
          : new Date(
              new Date(convertDateLikeMeet(Date.now())).setHours(
                convertDateLikeMeet(Date.now()).getHours() + 1
              )
            ),
      });
    },
    [editMode, reset, isClickable, dataSubscription?.defaultRate]
  );
  const [loadAvailabletime, { data: freeTimes, loading: loadingFreeTimes }] =
    useLazyQuery(DOCTOR_FREE_TIMES);
  React.useEffect(() => {
    if (modalAddOpen) {
      loadAvailabletime({
        variables: {
          doctorId: doctor?._id,
          startDate: dateNow === dateBgin ? startDateTime : alwaysDate,
          endDate: endDateTime,
        },
      });
    }
  }, [
    loadAvailabletime,
    modalAddOpen,
    endDateTime,
    dateNow,
    dateBgin,
    startDateTime,
    alwaysDate,
    doctor,
  ]);
  const doctorFreeTimes = freeTimes?.availableTimes;
  useEffect(() => {
    handleDefaultValues(eventData);
  }, [
    eventData,
    handleDefaultValues,
    isClickable,
    dataSubscription?.defaultRate,
  ]);

  function handleChangeDate(
    name: 'endDate' | 'startDate',
    value: string | number
  ): void {
    setDateRange((oldDateRange) => {
      const newDateRange = { ...oldDateRange };
      newDateRange[name] = value;
      handleChangeCurrentDate(newDateRange[name]);
      return newDateRange;
    });
  }

  async function handleAddPatient(data: any) {
    const result = await generateAccount({
      variables: {
        userInput: {
          ...data,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          username: data?.email,
        },
      },
    });
    const addedPatient = result?.data?.generateAccount?.patient;
    methods.reset({
      ...methods.getValues(),
      patient: {
        ...addedPatient,
        name: `${addedPatient?.firstname ?? ''} ${
          addedPatient?.middlename ?? ''
        } ${addedPatient?.lastname ?? ''}`,
      },
    });
    setAddPatientError(errors['email']);
    return true;
  }
  async function onSubmit(data: any) {
    // const occurence=2
    setIsSubmitted(true);
    const {
      title,
      description,
      location,
      conditionType,
      patient,
      members,
      sessionType,
      isPrepay,
      repeatOn,
      endOn,
      repeatEvery,
      numberOfRepeat,
      occurence,
      frequencyType,
    } = data;
    const {
      rate,
      duration,
      cptCode,
      __typename,
      locationInPerson,
      tags,
      ...rest
    } = sanitizeData(sessionType) || {};

    const { __typename: types, ...others } = cptCode || {};
    const meetingLink = `${config.EGGMED_DOCTOR_ROOM_LINK}${doctorVideoRoomData?.videoRoom?.room_id}`;
    const submitData: IAppointmentSubmittedData = {
      title,
      description: description && convertToRawAndStringify(description),
      location,
      locationLink: data?.activeMeeting ? meetingLink : '',
      conditionType: selectedColorId ? selectedColorId : undefined,
      doctor: doctorId,
      members: members ? members.map((member: any) => member.email) : [],
      patient: patient._id,
      startDate: convertTime(getDateWithOutTime, time, doctorData?.timeZone),
      isPrepay,
      sessionType: {
        ...rest,
        rate: rate?.toString(),
        duration: duration?.toString(),
        cptCode: { ...others },
        location: data?.activeMeeting ? 'Online' : 'In-person',
        locationPlace: data?.locationPlace,
        externalUrl: data?.externalUrl,
        locationId: data?.locationInPerson?._id,
        assignedRoles: (rest as any)?.assignedRoles?.map((el) => el?._id),
        assignedProviders: (rest as any)?.assignedProviders?.map(
          (el) => el?._id
        ),
      },
      repeatOn: frequencyExpand ? repeatOn : undefined,
      occurence:
        frequencyExpand && occurence && frequencyType === 'occurrence'
          ? Number(occurence)
          : undefined,
      endOn:
        frequencyExpand && !occurence && frequencyType === 'on'
          ? new Date(endOn)
          : undefined,
      repeatEvery: frequencyExpand ? repeatEvery : undefined,
      numberOfRepeat: frequencyExpand ? Number(numberOfRepeat) : undefined,
    };
    if (
      currentEvent?.__typename === Typename.Appointment &&
      currentEvent?.repeatEvery
    ) {
      setOpen(true);
      setInfos(submitData);
      handleBack(false);
      return;
    }
    if (pickedRightTime) {
      const result: any = await addEvent(submitData, undefined, true);
      if (result instanceof Error) {
        setErrorMsg(result?.message);
        return;
      }
      triggerSnack();
      setTimeout(() => closeModal(), 1000);
      handleClose();
      if (isFromConflict) {
        handleOpen();
      }
    }
  }
  async function handleEvent(data) {
    const {
      title,
      descriptionEvent,
      repeatOn,
      endOns,
      repeatEvery,
      numberOfRepeat,
      isImpact,
    } = data || {};
    const submitData = {
      title: title === '' ? '(No title)' : title,
      doctor: doctorId,
      description:
        descriptionEvent && convertToRawAndStringify(descriptionEvent),
      repeatOn: frequencyExpand ? repeatOn : undefined,
      endOn: frequencyExpand ? new Date(endOns) : undefined,
      repeatEvery: frequencyExpand ? repeatEvery : undefined,
      numberOfRepeat: frequencyExpand ? Number(numberOfRepeat) : undefined,
      startDate: convertTime(
        getDateWithOutTimeEventStart,
        dayjs(start).format('HH:mm'),
        doctorData?.timeZone
      ),
      endDate: convertTime(
        getDateWithOutTimeEventEnd,
        dayjs(end).format('HH:mm'),
        doctorData?.timeZone
      ),
      isImpact,
    };
    if (
      currentEvent?.__typename == Typename.Event &&
      currentEvent?.repeatEvery
    ) {
      setOpen(true);
      setInfos(submitData);
      handleBack(false);
      return;
    }
    if (
      submitData?.startDate >= submitData?.endDate
      // ||
      // dayjs(submitData?.endDate).isAfter(dayjs(submitData?.endOn))
    ) {
      setErrorMsg(
        'Please choose an end date that is later than the start date'
      );
      return;
    }
    const result: any = await handleSubmitEvent(submitData, undefined, true);
    if (result instanceof Error) {
      setErrorMsg(result?.message);
      return;
    }
    triggerSnack();
    setTimeout(() => closeModal(), 1000);
    handleClose();
    if (isFromConflict) {
      handleOpen();
    }
  }
  function handleClose() {
    closeModal();
    setErrorMsg('');
    setIsClickable && setIsClickable(false);
    setOpen(false);
    setFrequencyExpand(false);
    handleBack(false);
    setIsSubmitted(false);
  }
  return (
    <>
      <FormProvider {...methods}>
        <BasicModal
          open={modalAddOpen}
          onClose={() => {
            handleClose();
          }}
          handleClose={() => {
            handleClose();
          }}
          isFromModal
          isSlide
          editMode={editMode}
          feedMode={feedMode}
          deleteText={isSession ? t('Delete session') : t('Delete event')}
          handleDelete={handleOpenDelete}
          divider
          title={title}
          titlePadding="2rem"
          btnsPadding="1.7rem"
          onSubmit={isSession ? onSubmit : handleEvent}
          className={classes.appointmentModal}
          loading={
            loadingAddAppointment ||
            loadingEditAppointment ||
            loadingAddEvent ||
            loadingEditEvent
          }
        >
          <Box px="2rem">
            {isSession ? (
              <EventForm
                feedMode={feedMode}
                handleChangeDate={handleChangeDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                addPatientError={addPatientError}
                handleAddPatient={handleAddPatient}
                errorMsg={errorMsg}
                isPrepay={eventData?.isPrepay}
                timeValues={timeValues}
                time={time}
                setTime={setTime}
                doctorFreeTimes={doctorFreeTimes}
                loadingFreeTimes={loadingFreeTimes}
                editMode={editMode}
                clickableEvent={clickableEvent}
                isClickable={isClickable}
                generateAccountLoading={generateAccountLoading}
                errorGenerateAccount={errorGenerateAccount}
                getDateWithOutTime={getDateWithOutTime}
                selectedColorId={selectedColorId}
                setSelectedColorId={setSelectedColorId}
                handleClickTag={handleClick}
                handleOpenAddPatient={() => setOpenAddPatient(true)}
                pickedRightTime={isSubmitted && !pickedRightTime}
                setPickedRightTime={setPickedRightTime}
                isActivePayout={isActivePayout}
              />
            ) : (
              <EventTab errorMsg={errorMsg} />
            )}
          </Box>
        </BasicModal>
        <FormProvider {...methodsAddTag}>
          <BasicModal
            open={openTag}
            onClose={closeTag}
            handleClose={closeTag}
            isFromModal
            isSlide
            divider
            title={titleTag}
            titlePadding="2rem"
            onSubmit={onAddTag}
            loading={createLoading}
          >
            <TagModal
              currentRow={undefined}
              editMode={false}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </BasicModal>
        </FormProvider>
        <FormProvider {...methodsAddPatient}>
          <BasicModal
            open={openAddPatient}
            onClose={onAddPatientClose}
            handleClose={onAddPatientClose}
            isFromModal
            isSlide
            divider
            title={t('Create session > Add client')}
            titlePadding="2rem"
            onSubmit={onAddPatient}
            loading={generateAccountLoading}
            cancelText="Back"
          >
            <Box
              sx={{
                width: {
                  xs: '50vw',
                  lg: '50vw',
                  xl: '37vw',
                },
              }}
            >
              <AddPatientModal
                checked={checked}
                handleChange={handleChange}
                commonProps={commonProps}
                roles={roles}
                open={openAddPatient}
              />
            </Box>
          </BasicModal>
        </FormProvider>
        <BasicModal
          open={open}
          onClose={() => setOpen(false)}
          handleClose={() => setOpen(false)}
          isRegularModal
          title=""
          titlePadding="3rem"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            px={12}
            pb={6}
          >
            <RecurringModal
              handleClose={() => setOpen(false)}
              handleSubmitEvent={
                currentEvent?.__typename == Typename.Event
                  ? handleSubmitEvent
                  : addEvent
              }
              infos={infos}
              loading={loadingEditAppointment || loadingEditEvent}
              closeModal={closeModal}
              title={
                currentEvent?.__typename == Typename.Event
                  ? t('Edit recurring event(s)')
                  : t('Edit recurring session(s)')
              }
              icon={<RecurringModalIcon />}
              type={t('edit')}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <ModalDelete
        text={currentEvent?.title ? EventType.EVENT : EventType.APPOINTMENT}
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        onDelete={() => {
          handelDelete(currentEvent?._id);
          handleClose();
        }}
      />
    </>
  );
}

const RATE = gql`
  query rate($id: String) {
    rate(id: $id) {
      location
      isPrepayment
      locationPlace
      externalUrl
    }
  }
`;
